/* do not edit. this file is generated by npm run lib:icons and constantly overwritten */
import React from 'react'
/**
 * @deprecated Use icons from `@chilipiper/icons/figma-generated/src/icon` or
 *   `@chilipiper/icons/figma-generated/src/logo` instead
 */
export const SvgWarning = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.95126 2.66462C8.56264 1.8848 7.43659 1.8848 7.04797 2.66462L2.19125 12.4104C1.84938 13.0964 2.34643 13.9198 3.14289 13.9198H12.8563C13.6528 13.9198 14.1499 13.0964 13.808 12.4104L8.95126 2.66462ZM5.18633 1.73689C6.34058 -0.579288 9.65866 -0.579289 10.8129 1.73689L15.6696 11.4827C16.7104 13.5711 15.1758 15.9998 12.8563 15.9998H3.14289C0.823404 15.9998 -0.711169 13.5711 0.329605 11.4827L5.18633 1.73689L6.11715 2.20076L5.18633 1.73689ZM7.99966 5.73153C8.57403 5.73153 9.03966 6.19715 9.03966 6.77153V8.77837C9.03966 9.35275 8.57403 9.81837 7.99966 9.81837C7.42528 9.81837 6.95966 9.35275 6.95966 8.77837V6.77153C6.95966 6.19715 7.42528 5.73153 7.99966 5.73153ZM7.99962 10.7488C8.57399 10.7488 9.03962 11.2144 9.03962 11.7888V11.8691C9.03962 12.4434 8.57399 12.9091 7.99962 12.9091C7.42524 12.9091 6.95962 12.4434 6.95962 11.8691V11.7888C6.95962 11.2144 7.42524 10.7488 7.99962 10.7488Z'
      fill='currentColor'
    />
  </svg>
)
export default SvgWarning
