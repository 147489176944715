/* do not edit. this file is generated by npm run lib:icons and constantly overwritten */
import React from 'react'
/**
 * @deprecated Use icons from `@chilipiper/icons/figma-generated/src/icon` or
 *   `@chilipiper/icons/figma-generated/src/logo` instead
 */
export const SvgExclamationMark = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M8.69994 9.50005C8.69994 10.218 9.28197 10.8 9.99994 10.8C10.7179 10.8 11.2999 10.218 11.2999 9.50005H8.69994ZM11.2999 6.50005C11.2999 5.78208 10.7179 5.20005 9.99994 5.20005C9.28197 5.20005 8.69994 5.78208 8.69994 6.50005H11.2999ZM8.69994 13.01C8.69994 13.728 9.28197 14.31 9.99994 14.31C10.7179 14.31 11.2999 13.728 11.2999 13.01H8.69994ZM11.2999 13C11.2999 12.2821 10.7179 11.7 9.99994 11.7C9.28197 11.7 8.69994 12.2821 8.69994 13H11.2999ZM11.2999 9.50005V6.50005H8.69994V9.50005H11.2999ZM11.2999 13.01V13H8.69994V13.01H11.2999ZM20 10C20 4.4772 15.5228 4.88758e-05 9.99999 4.88758e-05V2.60005C14.0869 2.60005 17.4 5.91314 17.4 10H20ZM9.99999 4.88758e-05C4.47714 4.88758e-05 -1.21593e-05 4.4772 -1.21593e-05 10H2.59999C2.59999 5.91314 5.91308 2.60005 9.99999 2.60005V4.88758e-05ZM-1.21593e-05 10C-1.21593e-05 15.5229 4.47714 20 9.99999 20V17.4C5.91308 17.4 2.59999 14.087 2.59999 10H-1.21593e-05ZM9.99999 20C15.5228 20 20 15.5229 20 10H17.4C17.4 14.087 14.0869 17.4 9.99999 17.4V20Z' />
  </svg>
)
export default SvgExclamationMark
