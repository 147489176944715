import React from 'react'
import styled from 'styled-components'
import { Box, Flex, ScreenReaderOnly } from '../../../core-components'
import { validatePositioningProps } from '../../../helpers/validatePositioningProps'
import { PositioningProps } from '../../types'

type Props = {
  'data-test-id'?: string
  UNSAFE_hexColor?: string
} & PositioningProps

export const Loader = ({ 'data-test-id': testId, UNSAFE_hexColor, ...positioningProps }: Props) => {
  validatePositioningProps(positioningProps, 'Loader')

  return (
    <AnimatedLoader data-test-id={testId} w='min-content' {...positioningProps}>
      {[1, 2, 3].map(dot => (
        <Dot
          className='dot'
          key={dot}
          w={4}
          h={4}
          UNSAFE_hexColor={UNSAFE_hexColor}
          borderRadius='full'
        />
      ))}
      <ScreenReaderOnly>Loading</ScreenReaderOnly>
    </AnimatedLoader>
  )
}

const Dot = styled(Box)<{
  UNSAFE_hexColor?: string
}>`
  ${({ UNSAFE_hexColor, theme }) =>
    UNSAFE_hexColor
      ? `background: ${UNSAFE_hexColor};`
      : `background: ${theme.colors['bg/dots-loader']};`}
`

const AnimatedLoader = styled(Flex)`
  @keyframes bounce {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }

  .dot {
    border-radius: 100%;
    animation: bounce 1.4s infinite ease-in-out both;
  }

  .dot:first-child {
    animation-delay: -0.32s;
  }

  .dot:nth-child(2) {
    animation-delay: -0.16s;
  }
`
