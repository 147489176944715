import { ComponentProps, HTMLAttributes } from 'react'
import styled from 'styled-components'
import { SystemProps } from '../../new/theme'
import { Box } from '../../new/core-components/box'

type Props = SystemProps & HTMLAttributes<any>

/** @deprecated Use `Section` from `@chilipiper/design-system/src/new` instead. */
export type CardProps = Props

/** @deprecated Use `Section` from `@chilipiper/design-system/src/new` instead. */
export const Card = styled(Box).attrs(
  props =>
    ({
      boxShadow: 'border/on-major',
      p: 4,
      borderRadius: 8,
      bg: 'bg/major',
      ...props,
    }) as ComponentProps<typeof Box>
)``
