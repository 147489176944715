/* do not edit. this file is generated by npm run lib:icons and constantly overwritten */
import React from 'react'
export const IconError = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16' {...props}>
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M8 1.875a6.125 6.125 0 1 0 0 12.25 6.125 6.125 0 0 0 0-12.25ZM.125 8a7.875 7.875 0 1 1 15.75 0A7.875 7.875 0 0 1 .125 8ZM8 4.325c.483 0 .875.392.875.875V8a.875.875 0 1 1-1.75 0V5.2c0-.483.392-.875.875-.875ZM7.125 10.8c0-.483.392-.875.875-.875h.007a.875.875 0 0 1 0 1.75H8a.875.875 0 0 1-.875-.875Z'
      clipRule='evenodd'
    />
  </svg>
)
