/* do not edit. this file is generated by npm run lib:icons and constantly overwritten */
import React from 'react'
/**
 * @deprecated Use icons from `@chilipiper/icons/figma-generated/src/icon` or
 *   `@chilipiper/icons/figma-generated/src/logo` instead
 */
export const SvgIosSpinner = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.232 6.232a1 1 0 0 0 0-1.414L4.111 2.697A1 1 0 1 0 2.697 4.11l2.121 2.121a1 1 0 0 0 1.414 0Z'
      fill='currentColor'
      fillOpacity={0.8}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.5 7a1 1 0 0 1 0 2h-3a1 1 0 0 1 0-2h3Z'
      fill='currentColor'
      fillOpacity={0.64}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.232 9.768a1 1 0 0 0-1.414 0l-2.121 2.121a1 1 0 1 0 1.414 1.414l2.121-2.121a1 1 0 0 0 0-1.414Z'
      fill='currentColor'
      fillOpacity={0.48}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7 11.5a1 1 0 1 1 2 0v3a1 1 0 1 1-2 0v-3Z'
      fill='currentColor'
      fillOpacity={0.4}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.303 13.303a1 1 0 0 0 0-1.414l-2.121-2.121a1 1 0 0 0-1.414 1.414l2.121 2.121a1 1 0 0 0 1.414 0Z'
      fill='currentColor'
      fillOpacity={0.32}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.5 7a1 1 0 1 1 0 2h-3a1 1 0 1 1 0-2h3Z'
      fill='currentColor'
      fillOpacity={0.2}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.303 2.697a1 1 0 0 0-1.414 0L9.768 4.818a1 1 0 1 0 1.414 1.414l2.121-2.121a1 1 0 0 0 0-1.414Z'
      fill='currentColor'
      fillOpacity={0.12}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 .5a1 1 0 0 0-1 1v3a1 1 0 0 0 2 0v-3a1 1 0 0 0-1-1Z'
      fill='currentColor'
    />
  </svg>
)
export default SvgIosSpinner
