import { Session } from '@chilipiper/api-type-def'
import { ProductStatus } from '@chilipiper/api-type-def/src/license'
import { setUser } from '@sentry/react'
import { store } from '@chilipiper/utils'
import { jwtApiV2 } from './jwtApiV2'

const sessionStore = store
sessionStore.session.set(
  'session',
  {
    user: {},
    licences: {
      events: {
        status: ProductStatus.Inactive,
      },
      mildMeetings: {
        status: ProductStatus.Inactive,
      },
      meetings: {
        status: ProductStatus.Inactive,
      },
      concierge: {
        status: ProductStatus.Inactive,
      },
      chat: {
        status: ProductStatus.Inactive,
      },
      distro: {
        status: ProductStatus.Inactive,
      },
    },
    workspaces: {
      adminOf: [],
    },
    products: [],
  },
  false
)

declare global {
  interface Window {
    sessionId?: string
  }
}

export const create = (data: Session) => {
  sessionStore.session.set('session', data, true)
  setUser({ email: data.email })
}

export const update = (data: Session) => {
  sessionStore.session.set('session', data, true)
  setUser({ email: data.email })
}

export const getSession = (): Session => sessionStore.session.get('session')

export const email = () => getSession().email

// https://floatingapps.atlassian.net/browse/CPP-7861
// blocked by BE key managment
// Zapier integration needs old session token
export const token = () => getSession().token

export const fullName = () => {
  const user = getSession().user
  return user.displayName || `${user.platformFirstName} ${user.platformLastName}`
}

export const admin = () => {
  const session = getSession()
  return session.admin || session.workspaceAdmin || !!session.workspaces?.adminOf?.length
}

export const isWorkspaceAdmin = () => {
  const session = getSession()
  return session.workspaceAdmin || !!session.workspaces?.adminOf?.length
}

export const superAdmin = () => getSession().admin

export const adminOfWorkspace = (id: string) => {
  const session = getSession()
  return session.admin || (session.workspaces.adminOf || []).includes(id)
}

export const isInboxOnlyUser = () => {
  const session = getSession()
  return session.admin && session.products.length === 1 && session.products[0] === 'Inbox'
}

export const setSessionId = (id: string) => {
  store.set('sessionId', id)
  if (typeof window !== 'undefined') {
    window.sessionId = id
  }
}

export const getSessionId = () => {
  let sessionId
  if (typeof window !== 'undefined') {
    sessionId = window.sessionId
  }
  return sessionId || store.session.get('sessionId')
}

export const getUser = () => getSession().user

export const hasSalesforce = () => getSession().crm?.type === 'Salesforce'

export const hasHubspot = () => {
  const crm = getSession().crm
  return crm?.type === 'Hubspot' && !!crm?.id
}

export const getCrmType = () => getSession().crm?.type

export default {
  getSession,
}

export const endSession = (reload = true) => {
  jwtApiV2.logout().then(() => {
    if (reload) {
      window.location.reload()
    }
  })
}

export interface SessionData {
  tenantId: string
  userId: string
}
