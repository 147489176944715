import React, { ComponentProps, DOMAttributes } from 'react'
import * as R from 'remeda'
import { FocusableElement } from '@react-types/shared'
import { AriaButtonProps } from 'react-aria'
import {
  SvgCaution,
  SvgInform,
  SvgRemove,
  SvgSuccess,
  SvgWarning,
} from '@chilipiper/icons/src/design-system'
import invariant from 'tiny-invariant'
import { typeValidator } from '@chilipiper/utils'
import { Box, Flex, Text } from '../../../core-components'
import { ActionBarButton } from '../../action/action-bar/action-bar-button/ActionBarButton'
import { ActionBar } from '../../action'
import { darkTheme, ThemeColors, ThemeShadows, Variation } from '../../../theme'
import { ThemeProviderClean } from '../../../../ThemeProviderClean'
import { StartContent, StartContentProvider } from '../../data/start-content/StartContent'

type Actions = Array<{
  'data-test-id'?: string
  onClick: () => void
  text: string
  variant: ComponentProps<typeof ActionBarButton>['variant']
}>

type Props = (
  | {
      actions?: Actions
      'data-test-id'?: string
      onClose?: () => void
      type?: 'inline'
      actionsPlacement?: 'bottom' | 'right'
    }
  | {
      actions?: Actions
      closeButtonProps: AriaButtonProps<'button'>
      onClose: () => void
      titleProps: DOMAttributes<FocusableElement>
      contentProps: DOMAttributes<FocusableElement>
      descriptionProps: DOMAttributes<FocusableElement>
      type: 'floating'
      actionsPlacement?: never
    }
) & {
  description?: string
  isDismissable?: boolean
  startContent?: React.ReactNode
  title: string
  variant: 'neutral' | 'success' | 'inform' | 'warning' | 'caution'
}

const AlertBannerStartContent = (props: React.ComponentPropsWithoutRef<typeof StartContent>) => (
  <StartContent {...props} />
)

export const AlertBanner = (props: Props) => {
  invariant(
    props.startContent === undefined || typeValidator(AlertBannerStartContent)(props.startContent),
    'startContent must be a AlertBannerStartContent component or undefined'
  )
  const actionsPlacement =
    ('actionsPlacement' in props ? props.actionsPlacement : undefined) ?? 'bottom'
  const {
    contentContainerBg,
    closeButtonProps,
    titleProps,
    contentProps,
    descriptionProps,
    borderRadius,
    theme,
    boxShadow,
    dataTestId,
    bg,
  } = typeProps(props)
  return (
    <Box
      flexGrow={1}
      bg={bg}
      data-test-id={dataTestId}
      overflow='hidden'
      borderRadius={borderRadius}
      boxShadow={boxShadow}
      {...contentProps}
    >
      <ThemeProviderClean variation={theme}>
        <Flex
          bg={contentContainerBg}
          boxShadow={
            props.actions !== undefined && actionsPlacement === 'bottom' ? 'border/row' : undefined
          }
        >
          <Flex py={3} px={6} gap={3} flexGrow={1}>
            <StartContentProvider value={{ iconColor: iconColorMap[props.variant] }}>
              <Box mt={1} flexShrink={0}>
                {props.startContent ? (
                  props.startContent
                ) : (
                  <StartContent variant='icon' icon={iconMap[props.variant]} />
                )}
              </Box>
            </StartContentProvider>
            <Flex flexDirection='column' gap={1}>
              <Text textStyle='body-multiline' color='text/body-major' {...titleProps}>
                {props.title}
              </Text>
              {props.description && (
                <Text textStyle='body-multiline' color='text/body-moderate' {...descriptionProps}>
                  {props.description}
                </Text>
              )}
            </Flex>
          </Flex>
          {actionsPlacement === 'right' && (
            <Flex boxShadow='border/cell-left'>
              <AlertBannerActions actions={props.actions} onClose={props.onClose} variant='inRow' />
            </Flex>
          )}
          {props.isDismissable && (
            <Flex boxShadow='border/cell-left'>
              <ActionBar variant='inRow'>
                <ActionBar.IconButton
                  label='Close'
                  icon={SvgRemove}
                  onClick={props.onClose ?? R.doNothing()}
                  ariaProps={closeButtonProps}
                />
              </ActionBar>
            </Flex>
          )}
        </Flex>
        {actionsPlacement === 'bottom' && (
          <AlertBannerActions actions={props.actions} onClose={props.onClose} variant='nested' />
        )}
      </ThemeProviderClean>
    </Box>
  )
}

type AlertBannerActionsProps = {
  actions?: Actions
  onClose?: () => void
  variant: 'nested' | 'inRow'
}

const AlertBannerActions = ({ actions, onClose, variant }: AlertBannerActionsProps) => {
  if (!actions) {
    return null
  }

  return (
    <ActionBar variant={variant}>
      {actions.map(actionObject => (
        <ActionBar.Button
          key={actionObject.text}
          onClick={() => {
            actionObject.onClick()
            onClose?.()
          }}
          variant={actionObject.variant}
          data-test-id={actionObject['data-test-id']}
        >
          {actionObject.text}
        </ActionBar.Button>
      ))}
    </ActionBar>
  )
}

const variantBg = {
  neutral: 'bg/container-highlight',
  success: 'bg/feedback-success',
  inform: 'bg/feedback-inform',
  warning: 'bg/feedback-warning',
  caution: 'bg/feedback-caution',
} satisfies Record<Props['variant'], ThemeColors>

const iconColorMap = {
  inform: 'icon/inform',
  warning: 'icon/warning',
  success: 'icon/success',
  caution: 'icon/error',
  neutral: 'icon/indicator',
} as const

const iconMap = {
  inform: SvgInform,
  warning: SvgWarning,
  success: SvgSuccess,
  caution: SvgCaution,
  neutral: SvgInform,
}

const typeProps = (
  props: Props
): {
  bg?: ThemeColors
  boxShadow?: ThemeShadows
  borderRadius?: number
  dataTestId?: string
  theme?: Variation
  titleProps: DOMAttributes<FocusableElement>
  contentProps: DOMAttributes<FocusableElement>
  descriptionProps: DOMAttributes<FocusableElement>
  closeButtonProps?: AriaButtonProps<'button'>
  contentContainerBg?: ThemeColors
} => {
  if (props.type === 'floating') {
    return {
      bg: 'bg/toast',
      boxShadow: 'border/popover',
      borderRadius: 12,
      theme: darkTheme,
      titleProps: props.titleProps,
      contentProps: props.contentProps,
      descriptionProps: props.descriptionProps,
      closeButtonProps: props.closeButtonProps,
    }
  }
  return {
    dataTestId: props['data-test-id'],
    contentContainerBg: variantBg[props.variant],
    titleProps: {},
    contentProps: {},
    descriptionProps: {},
  }
}

AlertBanner.StartContent = AlertBannerStartContent
