/* do not edit. this file is generated by npm run lib:icons and constantly overwritten */
import React from 'react'
/**
 * @deprecated Use icons from `@chilipiper/icons/figma-generated/src/icon` or
 *   `@chilipiper/icons/figma-generated/src/logo` instead
 */
export const SvgInform = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' {...props}>
    <path
      d='M8 2.08a5.92 5.92 0 1 0 0 11.84A5.92 5.92 0 0 0 8 2.08ZM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8Zm8-3.448c.574 0 1.04.466 1.04 1.04V5.6a1.04 1.04 0 1 1-2.08 0v-.008c0-.574.466-1.04 1.04-1.04ZM8 7.36c.574 0 1.04.466 1.04 1.04v2.4a1.04 1.04 0 1 1-2.08 0V8.4c0-.574.466-1.04 1.04-1.04Z'
      fill='currentColor'
    />
  </svg>
)
export default SvgInform
