/* do not edit. this file is generated by npm run lib:icons and constantly overwritten */
import React from 'react'
/**
 * @deprecated Use icons from `@chilipiper/icons/figma-generated/src/icon` or
 *   `@chilipiper/icons/figma-generated/src/logo` instead
 */
export const SvgX = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' {...props}>
    <path
      d='M11.5635 4.436c.4062.4062.4062 1.0647 0 1.4709l-2.093 2.093 2.0931 2.093c.4061.4062.4061 1.0647 0 1.4708-.4062.4062-1.0647.4062-1.4708 0l-2.093-2.093-2.093 2.093c-.4062.4061-1.0647.4061-1.4708 0-.4062-.4062-.4062-1.0647 0-1.4708l2.093-2.093-2.093-2.093c-.4062-.4061-.4062-1.0646 0-1.4708.4061-.4061 1.0646-.4061 1.4707 0l2.093 2.093 2.0931-2.093c.4061-.4062 1.0646-.4062 1.4707 0Z'
      fill='currentColor'
    />
  </svg>
)
export default SvgX
