/* do not edit. this file is generated by npm run lib:icons and constantly overwritten */
import React from 'react'
/**
 * @deprecated Use icons from `@chilipiper/icons/figma-generated/src/icon` or
 *   `@chilipiper/icons/figma-generated/src/logo` instead
 */
export const SvgCaution = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' {...props}>
    <path
      d='M8 2C4.6863 2 2 4.6863 2 8s2.6863 6 6 6 6-2.6863 6-6-2.6863-6-6-6ZM0 8c0-4.4183 3.5817-8 8-8s8 3.5817 8 8-3.5817 8-8 8-8-3.5817-8-8Zm7 .5v-4h2v4H7ZM8 10c.5523 0 1 .4477 1 1v.1c0 .5523-.4477 1-1 1s-1-.4477-1-1V11c0-.5523.4477-1 1-1Z'
      fill='currentColor'
    />
  </svg>
)
export default SvgCaution
